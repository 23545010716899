<template>
  <div class="myPage">
    <el-container class="normalCon">
      <el-header>
        <div class="header">
          <div class="header_title">我的-佛山市灏海餐饮管理有限公司</div>
        </div>
      </el-header>
      <el-main>
        <div class="myMain">
          <div class="userBlock" @click="loginJump">
            <img src="../assets/user.png" alt="">
            <p>{{ username ? username : "尊敬的用户" }}</p>
          </div>
          <div class="orderMain" v-if="isCarList">
            <div class="orderTop">
              <div class="leftName">我的订单</div>
              <div class="rightName" @click="jumpOrder(2)">全部订单 > </div>
            </div>
            <div class="orderPart">
              <div class="orderVal" @click="jumpOrder(-1)">
                <el-badge :value="initNum.toBePayCount" class="item">
                  <img src="../assets/wait.png" alt="">
                  <p>待支付</p>
                </el-badge>
              </div>
              <div class="orderVal" @click="jumpOrder(0)">
                <el-badge :value="initNum.toBePickCount" class="item">
                  <img src="../assets/takeoff.png" alt="">
                  <p>待取货</p>
                </el-badge>
              </div>
              <div class="orderVal" @click="jumpOrder(1)">
                <el-badge :value="initNum.completeCount" class="item">
                  <img src="../assets/finish.png" alt="">
                  <p>已完成</p>
                </el-badge>
              </div>
            </div>
          </div>
          <div class="menuBlock">
            <div class="menuList" @click="jumpLink(1)">
              <i class="el-icon-user leftWay"></i>
              <span>用户信息修改</span>
              <i class="el-icon-arrow-right rightWay"></i>
            </div>
            <div class="menuList" @click="jumpLink(2)">
              <i class="el-icon-coordinate leftWay"></i>
              <span>管理地址信息</span>
              <i class="el-icon-arrow-right rightWay"></i>
            </div>
          </div>

          <div class="btnDropOut" v-if="isLogin">
            <el-button @click="dropOutLogin" type="danger">退出登录</el-button>
          </div>
        </div>
      </el-main>
      <el-footer>
        <footer-part />
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import footerPart from "@/components/footer";
import VueBarCode from "vue-barcode";
export default {
  data() {
    return {
      username: null, // 用户名称
      isLogin: true,
      barNum: this.$store.state.buyerId,
      isCarList: true,
      isShowManage: true,
      isShowValetWorship: true,
      isShowAppoint: true,
      isShowBusiness: true,
      initNum: {
        completeCount: 0,
        toBePayCount: 0,
        toBePickCount: 0,
        totalCount: 0
      }
    }
  },
  components: {
    footerPart,
    VueBarCode
  },
  created() {
    this.getOrderNum();
  },
  mounted() {
    this.checkLogin();
    this.checkShow();
  },
  methods: {
    // 退出登录
    dropOutLogin() {
      this.$confirm('是否退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '退出登录成功!',
          duration: 1500,
          onClose: () => {
            sessionStorage.clear();
            this.$store.dispatch("logout")
            this.$cookies.remove("userinfo");
            this.$cookies.remove("shopToken");
            this.$router.replace({ name: "Home" });
          },
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消退出登录'
        });
      });
    },
    // 检查登录
    checkLogin() {
      let showToken = this.$cookies.get("shopToken"); // 获取token
      if (showToken) {  // 判断有token就获取用户
        this.username = this.$store.state.name;
        this.isLogin = true;
      } else {
         // this.$router.push({ name: "Login" });
        this.isLogin = false;
        // this.$cookies.remove("isCarList");
        this.$cookies.remove("shopToken");
        this.$cookies.remove("isRead");
      }
    },
    checkShow() {
      // 检查显示的
      this.isCarList = (JSON.parse(this.$cookies.get("isCarList")));
      this.isShowManage = (JSON.parse(this.$cookies.get("isManageList"))) ;
      this.isShowValetWorship = (JSON.parse(this.$cookies.get("isValetWorshipList")));
      this.isShowAppoint = (JSON.parse(this.$cookies.get("isAppointList")));
      this.isShowBusiness = (JSON.parse(this.$cookies.get("isBusinessAppoint")) === 1);
    },
    // 跳转登录判断
    loginJump() {
      let showToken = this.$cookies.get("shopToken");
      if (!showToken) {
        this.$router.replace({ name: "Login", params: { lastLogin: "mine" } });
      }
    },
    jumpLink(type) {
      if (type === 1) {
        this.$router.push({ name: "person" });  // 跳转到用户信息修改页
      } else if (type === 2) {
        this.$router.push({ name: "address", query: {type: 0} });  // 跳转到代客祭拜订单页
      }
    },
    getOrderNum() {
      let showToken = this.$cookies.get("shopToken"); // 获取token
      let getBuyer = JSON.parse(sessionStorage.getItem("store"));
      if (showToken) {
        this.$http.httpGet(`/mobile/onlineShop/getRetailStatusCountByBuyerId`, {
          buyerId: getBuyer.buyerId,
        }).then(data =>{
          if (data && data.code === 0) {
            this.initNum = {...data.byRetailStatusCountVO}
          } else {
            this.initNum.completeCount = 0;
            this.initNum.toBePayCount = 0;
            this.initNum.toBePickCount = 0;
            this.initNum.totalCount = 0;
          }
        })
      }
    },
    // 我的订单跳转事件
    jumpOrder(num) {
      this.$router.replace({ name: "order", query: { type: num } });
    },
  },
}
</script>

<style scoped lang="scss">
.memberCode::v-deep {
  position: relative;
  padding: 0;
  margin: 0 0 15px;
  background-color: #fff;
  p {
    font-size: 16px;
    margin: 0;
    padding: 1.3rem;
    text-align: left !important;
  }
  .vue-barcode-element {
    width: 100%;
  }
}
</style>
